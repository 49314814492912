import React from 'react';
import { Box } from '@mui/material';
import { FieldSet } from '../../core/components';
import {
	isDraftMessageVisible,
	setInitialDraftEditorState,
} from '../../../shared/modules/draft/helpers';
import PureDraftEditor from '../../../shared/modules/draft/PureDraftEditor';
import { Workflow } from '../../core/types/api';

function WorkflowEntryPreview({ message, systemMessage, createdBy }: Workflow) {
	const { authorName } = createdBy || {};

	const isMessageExisting = React.useMemo(() => isDraftMessageVisible(message), [message]);

	if (!isMessageExisting && !systemMessage) return null;

	return (
		<FieldSet
			legend={
				<>
					Last message
					{authorName && <> by {authorName}</>}
				</>
			}
		>
			{systemMessage && <Box fontSize="12px">{systemMessage}</Box>}
			{message && (
				<Box>
					<PureDraftEditor
						editorState={setInitialDraftEditorState(message)}
						onChange={() => undefined}
						readOnly
					/>
				</Box>
			)}
		</FieldSet>
	);
}

function WorkflowEntryLister({ workflows }) {
	if (!workflows) return null;

	const workflow = workflows?.filter((w) => !!w.message || !!w.systemMessage)[0];

	if (!workflow) return null;

	return <WorkflowEntryPreview {...workflow} />;
}

export default React.memo(WorkflowEntryLister);
