/* Autogenerated file. Do not edit, otherwise your changes will be eventually overwritten. */

export default {
	'Account/CreateUser': [1],
	'Account/Login': [],
	'Account/LoginImpersonated': [],
	'Account/SendCodeViaEmail': [],
	'Account/ValidateCode': [],
	'Account/ValidateRecoveryCode': [],
	'Account/SendConfirmEmail': [],
	'Account/ConfirmEmail': [],
	'Account/LockoutEnd': [],
	'Account/ConfirmAccountNoPassword': [],
	'Account/EditProfileSettings': [1, 2, 4, 8, 16, 32, 64, 128, 512, 1024],
	'Account/AcceptPolicy': [],
	'Account/EnableTwoFactorAuthentication': [],
	'Account/SharedKeyQrCodeUri': [],
	'Account/ResetAuthenticator': [],
	'Account/ForgotPassword': [],
	'Account/ResetPassword': [],
	'Account/ChangePassword': [],
	'Account/ChangeUserPassword': [1, 2, 4],
	'Account/SetUserEnabled': [1, 2, 4],
	'Account/EditUserSetLockoutEnd': [1, 2, 4],
	'Account/EditUserInfo': [1, 2, 4],
	'Account/ResetRecoveryCodes': [],
	'Account/ViewUsersListToInvite': [1, 2, 4, 8, 16],
	'Account/GetContactInfoToRegister': [],
	'Account/RegisterByInvitation': [],
	'Account/UserByInvitationDecision': [1, 2, 4, 8, 16],
	'Account/GetUserMessages': [],
	'Account/MarkMessageAsRead': [],
	'Account/ViewUsers': [1, 2, 4],
	'Account/DeleteUser': [1],
	'Account/Logout': [],
	'Account/GetUserInfo': [],
	'Account/ViewProfile': [1, 2, 4, 8, 16, 32, 64, 128, 512, 1024],
	'Account/Unsubscribe': [],
	'Account/TestStart': [],
	'Account/GetTestEmails': [],
	'Approaches/View': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Approaches/Add': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Approaches/Update': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Approaches/Delete': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Approaches/ListControlMatrices': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Approaches/AddControlsMatrix': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Approaches/UpdateControlsMatrix': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Approaches/DeleteControlsMatrix': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	ArrayItems: [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'ArrayItems/Import': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'Attachments/List': [1, 2, 4, 8, 16, 32, 128, 1024],
	'Attachments/ListUsages': [1, 2, 4, 8, 16, 32, 128, 1024],
	'Attachments/Create': [1, 2, 4, 8, 16, 32, 128, 1024],
	'Attachments/Update': [1, 2, 4, 8, 16, 128, 1024],
	'Attachments/Delete': [1, 2, 4, 8, 16],
	'Attachments/DeleteAllUnused': [1, 2, 4, 8, 16],
	'AuditLog/SetAuditLogSetting': [1, 2],
	'AuditLog/ViewAuditLogSetting': [1, 2, 4, 8],
	'AuditLog/ViewAuditLog': [1, 2, 4, 8],
	'Backups/CreateBackup': [1],
	'Backups/ListMonth': [1],
	'Backups/RefreshList': [1],
	'Backups/Restore': [1],
	'Brand/Serve': [],
	'Channels/view': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'Client/RegisterClient': [1, 2, 4, 8, 16],
	'Client/EditClient': [1, 2, 4, 8, 16],
	'Client/DeleteClient': [1, 2, 4],
	'Client/GetMyClients': [1, 2, 4, 8, 16, 32],
	'Client/ViewClientUsersOfProject': [1, 2, 4, 8, 16, 32, 128, 1024],
	'Client/ViewClientUsers': [1, 2, 4],
	'Client/DownloadTemplate': [1, 2, 4, 8, 16],
	'Client/Import': [1, 2, 4, 8, 16],
	'Company/RegisterCompany': [1],
	'Company/UpdateCompany': [1, 2, 4],
	'Company/DeleteCompany': [1],
	'Company/GetUserCompanies': [1, 2, 4],
	'Company/ViewCompaniesOfUser': [1, 2, 4],
	'Company/CreateCompanyUser': [1],
	'Company/DeleteCompanyUser': [1],
	'CompanyToken/List': [1, 2, 4, 8, 16],
	'CompanyToken/Deactivate': [1, 2, 4, 8, 16],
	'CompanyToken/Resend': [1, 2, 4, 8, 16, 1024],
	'CompensatingControls/createCompensatingControl': [1, 2, 4, 8, 16, 32, 64],
	'CompensatingControls/editCompensatingControl': [1, 2, 4, 8, 16, 32, 64],
	'CompensatingControls/deleteCompensatingControl': [1, 2, 4, 8, 16, 32, 64],
	'CompensatingControls/viewCompensatingControls': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'Contact/View': [1, 2, 4, 8, 16, 32, 128, 1024],
	'Contact/CreateContact': [1, 2, 4, 8, 16, 32, 1024],
	'Contact/UpdateContact': [1, 2, 4, 8, 16, 32, 1024],
	'Contact/DeleteContact': [1, 2, 4, 8, 16, 32],
	'Contact/GetClientContacts': [1, 2, 4, 8, 16, 32, 128, 1024],
	'Contact/DownloadTemplate': [1, 2, 4, 8, 16, 32, 1024],
	'Contact/Import': [1, 2, 4, 8, 16, 32, 1024],
	'CustomSaq/List': [1, 2, 4, 8, 16],
	'CustomSaq/ViewSummaries': [1, 2, 4],
	'CustomSaq/CreateOrUpdate': [1, 2, 4],
	'CustomSaq/Remove': [1, 2, 4],
	'CustomSaq/AddOrRemoveSummary': [1, 2, 4],
	'CustomSaq/Clone': [1, 2, 4],
	'Dashboard/ProjectTrends': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'EmailSettings/GetServiceStatus': [1],
	'EmailSettings/SendTestEmail': [1],
	'EmailSettings/ListEmails': [1],
	'EmailSettings/ViewEmail': [1],
	'Evidences/Create': [1, 2, 4, 8, 16, 32, 128, 1024],
	'Evidences/UpdateAttachment': [1, 2, 4, 8, 16, 128, 1024],
	'Evidences/EvidenceDownload': [1, 2, 4, 8, 16, 32, 128, 256, 1024],
	'Evidences/ArchiveQuery': [1, 2, 4, 8, 16],
	'Evidences/DownloadArchive': [],
	'HelpDesk/Issue': [1, 2, 4, 8, 16],
	'HelpDesk/ReadSupportMessages': [1, 2, 4, 8, 16],
	'HelpDesk/ListSupportMessaging': [1, 2, 4, 8, 16],
	'HelpDesk/ViewAttachment': [1, 2, 4, 8, 16],
	'HelpDesk/NotifySupportClientEvent': [1, 2, 4, 8, 16],
	'HelpDesk/ViewArticle': [1, 2, 4, 8, 16],
	'Hints/add': [1, 2, 4, 8, 16, 32, 64],
	'Hints/update': [1, 2, 4, 8, 16, 32, 64],
	'Hints/delete': [1, 2, 4, 8, 16, 32, 64],
	'Interview/CreateInterview': [1, 2, 4, 8, 16, 32, 1024],
	'Interview/UpdateInterview': [1, 2, 4, 8, 16, 32, 1024],
	'Interview/DeleteInterview': [1, 2, 4, 8, 16, 32],
	'Interview/Export': [1, 2, 4, 8, 16, 32, 1024],
	'Interview/GetInterview': [1, 2, 4, 8, 16, 32, 1024],
	'Interview/ViewInterviews': [1, 2, 4, 8, 16, 32, 1024],
	'Interview/GetProjectInterviews': [1, 2, 4, 8, 16, 32, 1024],
	'Interview/Import': [1, 2, 4, 8, 16, 32, 64],
	'Logger/Log': [],
	'Mpa/ViewMpaRequests': [1, 2],
	'Mpa/ApproveMpaRequest': [1, 2],
	'Mpa/ViewMpaRequestsPendingCount': [1, 2],
	'Mpa/ViewMpaRequestsActions': [1, 2],
	'Mpa/SetActionEnabled': [1],
	'Options/Get': [1],
	'Options/Set': [1],
	'Options/CheckLicense': [1],
	'PlatinumTemplate/Update': [1],
	'Project/CreateProject': [1, 2, 4, 8, 16],
	'Project/RestartFailedReportCreationPart': [1, 2, 4, 8, 16],
	'Project/RecalculateProgress': [1, 2, 4, 8, 16, 32],
	'Project/RunScripts': [1, 2, 4, 8, 16, 32],
	'Project/MapScope': [1, 2, 4, 8, 16],
	'Project/EditProject': [1, 2, 4, 8, 16],
	'Project/DeleteProject': [1, 2, 4],
	'Project/ViewProjects': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'Project/ViewInfo': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'Project/ViewTemplateProjects': [1],
	'Project/ViewUsersOfProject': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'Project/SendInvitation': [1, 2, 4, 8, 16, 1024],
	'Project/CreateProjectUser': [1, 2, 4, 8, 16],
	'Project/EditProjectUser': [1, 2, 4, 8, 16],
	'Project/DeleteProjectUser': [1, 2, 4, 8, 16],
	'Project/AssignUserToRequirement': [1, 2, 4, 8, 16],
	'Project/MigrateVersion': [],
	'RemoteCommand/Request': [],
	'RemoteCommand/ListLocally': [1],
	'RemoteCommand/ListRemotely': [],
	'RemoteCommand/Execute': [1],
	'RemoteCommand/GetDockerInfoRemotely': [],
	'RemoteCommand/SetReleaseTypeRemotely': [],
	'RemoteCommand/RecreateContainersRemotely': [],
	'RemoteCommand/GetClientInfoRemotely': [],
	'RemoteCommand/GetAmiVersion': [],
	'RemoteCommand/GetBackupStatus': [],
	'RemoteCommand/ListBackups': [],
	'RemoteCommand/RefreshBackups': [],
	'RemoteCommand/CreateDemoEnvironment': [],
	'RemoteCommand/ForceCheckLicense': [],
	'RemoteCommand/UpdateUserHelpArticle': [],
	'Report/ViewStatusReport': [1, 2, 4, 8, 16],
	'Report/DownloadStatusReport': [1, 2, 4, 8, 16],
	'ResponsibilityArea/View': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'ResponsibilityArea/ViewRequirementsForArea': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'ResponsibilityArea/SendBulkResponseRequests': [1, 2, 4, 8, 16, 32, 128, 1024],
	'ResponsibilityArea/GetItems': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'ResponsibilityArea/GetFilters': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RiskTool/ViewAnalyses': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'RiskTool/UpdateAnalysis': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'RiskTool/DeleteAnalysis': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'RiskTool/GetNewEntry': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'RiskTool/ExportXlsx': [],
	'RocItemControllerNew/report/setValue': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'RocItemControllerNew/report/approveValue': [1, 2, 4, 8, 16, 32],
	'RocItemControllerNew/report/deleteValue': [1, 2, 4, 8, 16, 32],
	'RocItemControllerNew/report/view': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocItemControllerNew/ViewReportNextRequest': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocItemControllerNew/ViewReportNextPage': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocItemControllerNew/ViewReportNextItem': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocItemControllerNew/report/addArrayItem': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'RocItemControllerNew/report/deleteArrayItem': [1, 2, 4, 8, 16, 32, 64],
	'RocItemControllerNew/getRocItemInterviewInfo': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocItemControllerNew/searchItem': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocItemControllerNew/textHistory': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocItemControllerNew/SetNonComplianceReason': [1, 2, 4, 8, 16, 32, 64],
	'RocItemControllerNew/SetApplicable': [1, 2, 4, 8, 16, 32],
	'RocItemControllerNew/ClearWarnings': [1, 2, 4, 8, 16, 32, 64],
	'RocItemControllerNew/SaqOverride': [1, 2, 4, 8, 16, 32, 64, 128, 1024],
	'RocItemEditor/template/view': [1],
	'RocItemEditor/add': [1],
	'RocItemEditor/edit': [1],
	'RocItemEditor/delete': [1],
	'RocLookUp/View': [1, 2, 4, 8, 16, 32, 64],
	'RocLookUp/Use': [1, 2, 4, 8, 16, 32, 64],
	'RocModelControllerNew/template/list': [1],
	'RocModelControllerNew/template/details': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocModelControllerNew/Export': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocModelControllerNew/ExportItem': [],
	'RocModelControllerNew/NA': [],
	'RocModelControllerNew/ViewRequirements': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'RocModelControllerNew/Search': [1, 2, 4, 8, 16, 32],
	'Role/AddRoleToUser': [1, 2, 4],
	'Role/RemoveRoleFromUser': [1, 2, 4],
	'SampleSets/GenerateTrackingSheet': [1, 2, 4, 8, 16, 32, 64],
	'SampleSets/UploadTrackingSheet': [1, 2, 4, 8, 16, 32, 64],
	'SampleSets/DeleteTrackingSheet': [1, 2, 4, 8, 16, 32, 64],
	'Snippets/List': [1, 2, 4, 8, 16, 32, 64],
	'Snippets/CreateOrUpdate': [1, 2, 4, 8, 16, 32, 64],
	'Snippets/Delete': [1, 2, 4, 8, 16, 32, 64],
	'Workflow/List': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'Workflow/Create': [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
	'Workflow/Update': [],
	'Workflow/TempLogin': [],
	'Workflow/GetUserInfoForRegistration': [],
	'Workflow/RegisterByToken': [],
	'Workflow/ApproveFile': [1, 2, 4, 8, 16],
} as Record<string, number[]>;

export type ServerMethodNames =
	| 'Account/CreateUser'
	| 'Account/Login'
	| 'Account/LoginImpersonated'
	| 'Account/SendCodeViaEmail'
	| 'Account/ValidateCode'
	| 'Account/ValidateRecoveryCode'
	| 'Account/SendConfirmEmail'
	| 'Account/ConfirmEmail'
	| 'Account/LockoutEnd'
	| 'Account/ConfirmAccountNoPassword'
	| 'Account/EditProfileSettings'
	| 'Account/AcceptPolicy'
	| 'Account/EnableTwoFactorAuthentication'
	| 'Account/SharedKeyQrCodeUri'
	| 'Account/ResetAuthenticator'
	| 'Account/ForgotPassword'
	| 'Account/ResetPassword'
	| 'Account/ChangePassword'
	| 'Account/ChangeUserPassword'
	| 'Account/SetUserEnabled'
	| 'Account/EditUserSetLockoutEnd'
	| 'Account/EditUserInfo'
	| 'Account/ResetRecoveryCodes'
	| 'Account/ViewUsersListToInvite'
	| 'Account/GetContactInfoToRegister'
	| 'Account/RegisterByInvitation'
	| 'Account/UserByInvitationDecision'
	| 'Account/GetUserMessages'
	| 'Account/MarkMessageAsRead'
	| 'Account/ViewUsers'
	| 'Account/DeleteUser'
	| 'Account/Logout'
	| 'Account/GetUserInfo'
	| 'Account/ViewProfile'
	| 'Account/Unsubscribe'
	| 'Account/TestStart'
	| 'Account/GetTestEmails'
	| 'Approaches/View'
	| 'Approaches/Add'
	| 'Approaches/Update'
	| 'Approaches/Delete'
	| 'Approaches/ListControlMatrices'
	| 'Approaches/AddControlsMatrix'
	| 'Approaches/UpdateControlsMatrix'
	| 'Approaches/DeleteControlsMatrix'
	| 'ArrayItems'
	| 'ArrayItems/Import'
	| 'Attachments/List'
	| 'Attachments/ListUsages'
	| 'Attachments/Create'
	| 'Attachments/Update'
	| 'Attachments/Delete'
	| 'Attachments/DeleteAllUnused'
	| 'AuditLog/SetAuditLogSetting'
	| 'AuditLog/ViewAuditLogSetting'
	| 'AuditLog/ViewAuditLog'
	| 'Backups/CreateBackup'
	| 'Backups/ListMonth'
	| 'Backups/RefreshList'
	| 'Backups/Restore'
	| 'Brand/Serve'
	| 'Channels/view'
	| 'Client/RegisterClient'
	| 'Client/EditClient'
	| 'Client/DeleteClient'
	| 'Client/GetMyClients'
	| 'Client/ViewClientUsersOfProject'
	| 'Client/ViewClientUsers'
	| 'Client/DownloadTemplate'
	| 'Client/Import'
	| 'Company/RegisterCompany'
	| 'Company/UpdateCompany'
	| 'Company/DeleteCompany'
	| 'Company/GetUserCompanies'
	| 'Company/ViewCompaniesOfUser'
	| 'Company/CreateCompanyUser'
	| 'Company/DeleteCompanyUser'
	| 'CompanyToken/List'
	| 'CompanyToken/Deactivate'
	| 'CompanyToken/Resend'
	| 'CompensatingControls/createCompensatingControl'
	| 'CompensatingControls/editCompensatingControl'
	| 'CompensatingControls/deleteCompensatingControl'
	| 'CompensatingControls/viewCompensatingControls'
	| 'Contact/View'
	| 'Contact/CreateContact'
	| 'Contact/UpdateContact'
	| 'Contact/DeleteContact'
	| 'Contact/GetClientContacts'
	| 'Contact/DownloadTemplate'
	| 'Contact/Import'
	| 'CustomSaq/List'
	| 'CustomSaq/ViewSummaries'
	| 'CustomSaq/CreateOrUpdate'
	| 'CustomSaq/Remove'
	| 'CustomSaq/AddOrRemoveSummary'
	| 'CustomSaq/Clone'
	| 'Dashboard/ProjectTrends'
	| 'EmailSettings/GetServiceStatus'
	| 'EmailSettings/SendTestEmail'
	| 'EmailSettings/ListEmails'
	| 'EmailSettings/ViewEmail'
	| 'Evidences/Create'
	| 'Evidences/UpdateAttachment'
	| 'Evidences/EvidenceDownload'
	| 'Evidences/ArchiveQuery'
	| 'Evidences/DownloadArchive'
	| 'HelpDesk/Issue'
	| 'HelpDesk/ReadSupportMessages'
	| 'HelpDesk/ListSupportMessaging'
	| 'HelpDesk/ViewAttachment'
	| 'HelpDesk/NotifySupportClientEvent'
	| 'HelpDesk/ViewArticle'
	| 'Hints/add'
	| 'Hints/update'
	| 'Hints/delete'
	| 'Interview/CreateInterview'
	| 'Interview/UpdateInterview'
	| 'Interview/DeleteInterview'
	| 'Interview/Export'
	| 'Interview/GetInterview'
	| 'Interview/ViewInterviews'
	| 'Interview/GetProjectInterviews'
	| 'Interview/Import'
	| 'Logger/Log'
	| 'Mpa/ViewMpaRequests'
	| 'Mpa/ApproveMpaRequest'
	| 'Mpa/ViewMpaRequestsPendingCount'
	| 'Mpa/ViewMpaRequestsActions'
	| 'Mpa/SetActionEnabled'
	| 'Options/Get'
	| 'Options/Set'
	| 'Options/CheckLicense'
	| 'PlatinumTemplate/Update'
	| 'Project/CreateProject'
	| 'Project/RestartFailedReportCreationPart'
	| 'Project/RecalculateProgress'
	| 'Project/RunScripts'
	| 'Project/MapScope'
	| 'Project/EditProject'
	| 'Project/DeleteProject'
	| 'Project/ViewProjects'
	| 'Project/ViewInfo'
	| 'Project/ViewTemplateProjects'
	| 'Project/ViewUsersOfProject'
	| 'Project/SendInvitation'
	| 'Project/CreateProjectUser'
	| 'Project/EditProjectUser'
	| 'Project/DeleteProjectUser'
	| 'Project/AssignUserToRequirement'
	| 'Project/MigrateVersion'
	| 'RemoteCommand/Request'
	| 'RemoteCommand/ListLocally'
	| 'RemoteCommand/ListRemotely'
	| 'RemoteCommand/Execute'
	| 'RemoteCommand/GetDockerInfoRemotely'
	| 'RemoteCommand/SetReleaseTypeRemotely'
	| 'RemoteCommand/RecreateContainersRemotely'
	| 'RemoteCommand/GetClientInfoRemotely'
	| 'RemoteCommand/GetAmiVersion'
	| 'RemoteCommand/GetBackupStatus'
	| 'RemoteCommand/ListBackups'
	| 'RemoteCommand/RefreshBackups'
	| 'RemoteCommand/CreateDemoEnvironment'
	| 'RemoteCommand/ForceCheckLicense'
	| 'RemoteCommand/UpdateUserHelpArticle'
	| 'Report/ViewStatusReport'
	| 'Report/DownloadStatusReport'
	| 'ResponsibilityArea/View'
	| 'ResponsibilityArea/ViewRequirementsForArea'
	| 'ResponsibilityArea/SendBulkResponseRequests'
	| 'ResponsibilityArea/GetItems'
	| 'ResponsibilityArea/GetFilters'
	| 'RiskTool/ViewAnalyses'
	| 'RiskTool/UpdateAnalysis'
	| 'RiskTool/DeleteAnalysis'
	| 'RiskTool/GetNewEntry'
	| 'RiskTool/ExportXlsx'
	| 'RocItemControllerNew/report/setValue'
	| 'RocItemControllerNew/report/approveValue'
	| 'RocItemControllerNew/report/deleteValue'
	| 'RocItemControllerNew/report/view'
	| 'RocItemControllerNew/ViewReportNextRequest'
	| 'RocItemControllerNew/ViewReportNextPage'
	| 'RocItemControllerNew/ViewReportNextItem'
	| 'RocItemControllerNew/report/addArrayItem'
	| 'RocItemControllerNew/report/deleteArrayItem'
	| 'RocItemControllerNew/getRocItemInterviewInfo'
	| 'RocItemControllerNew/searchItem'
	| 'RocItemControllerNew/textHistory'
	| 'RocItemControllerNew/SetNonComplianceReason'
	| 'RocItemControllerNew/SetApplicable'
	| 'RocItemControllerNew/ClearWarnings'
	| 'RocItemControllerNew/SaqOverride'
	| 'RocItemEditor/template/view'
	| 'RocItemEditor/add'
	| 'RocItemEditor/edit'
	| 'RocItemEditor/delete'
	| 'RocLookUp/View'
	| 'RocLookUp/Use'
	| 'RocModelControllerNew/template/list'
	| 'RocModelControllerNew/template/details'
	| 'RocModelControllerNew/Export'
	| 'RocModelControllerNew/ExportItem'
	| 'RocModelControllerNew/NA'
	| 'RocModelControllerNew/ViewRequirements'
	| 'RocModelControllerNew/Search'
	| 'Role/AddRoleToUser'
	| 'Role/RemoveRoleFromUser'
	| 'SampleSets/GenerateTrackingSheet'
	| 'SampleSets/UploadTrackingSheet'
	| 'SampleSets/DeleteTrackingSheet'
	| 'Snippets/List'
	| 'Snippets/CreateOrUpdate'
	| 'Snippets/Delete'
	| 'Workflow/List'
	| 'Workflow/Create'
	| 'Workflow/Update'
	| 'Workflow/TempLogin'
	| 'Workflow/GetUserInfoForRegistration'
	| 'Workflow/RegisterByToken'
	| 'Workflow/ApproveFile';
